<h1 [class.subheadline]="isSubHeadline !== undefined">
  {{ headline }}
  <span *ngIf="headlineCount !== undefined" class="count">
    ({{ headlineCount }})
  </span>
</h1>

<a class="btn rounded-full" *ngIf="link" routerLink="{{ link }}" [queryParams]="linkParams ?? {}">
  {{ buttonText }}
  <fa-icon [icon]="faArrowRight"/>
</a>
