import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-section-header',
    templateUrl: './section-header.component.html',
    styleUrls: [ './section-header.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ NgIf, RouterLink, FaIconComponent ]
})
export class SectionHeaderComponent {
  @Input({required: true}) headline!: string;
  @Input() headlineCount?: number;
  @Input() isSubHeadline?: boolean | string;
  @Input() buttonText?: string;
  @Input() link?: string | number;
    @Input() linkParams?: Record<string, string>;
  faArrowRight = faArrowRight;
}
